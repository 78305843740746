import { Link } from "gatsby";
import React from "react";

export type HeaderNavigationLinkType = {
  label: string;
  to: string;
};

interface Props {
  link: HeaderNavigationLinkType;
}

const HeaderNavigationLink = (props: Props) => {
  const { link } = props;
  return (
    <Link
      to={link.to}
      className="text-white flex items-center text-xs p-1 sm:text-sm lg:text-base"
      activeClassName="active-link"
    >
      {link.label}
    </Link>
  );
};

export default HeaderNavigationLink;

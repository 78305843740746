import { Link } from "gatsby";
import React from "react";

interface Props {}

type navItemType = {
  title: {
    cz: string;
    en?: string;
  };
  to: string;
  subNav?: navItemType[];
};

export const navItems: navItemType[] = [
  {
    title: {
      cz: "Tkané etikety",
    },
    to: "tkane-etikety",
  },
  {
    title: {
      cz: "Sublimace",
    },
    to: "sublimace",
    // subNav: [
    //   {
    //     title: {
    //       cz: "Termotisk",
    //     },
    //     to: "termotisk",
    //   },
    //   {
    //     title: {
    //       cz: "Sublimace",
    //     },
    //     to: "sublimace",
    //   },
    //   {
    //     title: {
    //       cz: "Tisk z fólie",
    //     },
    //     to: "tisk-z-folie",
    //   },
    // ],
  },
  {
    title: {
      cz: "Termotransfer",
    },
    to: "termotransfer",
    // subNav: [
    //   {
    //     title: {
    //       cz: "Samolepící",
    //     },
    //     to: "samolepici",
    //   },
    //   {
    //     title: {
    //       cz: "Visačky, vizitky...",
    //     },
    //     to: "visacky-vizitky",
    //   },
    //   {
    //     title: {
    //       cz: "Tisk z fólie",
    //     },
    //     to: "tisk-z-folie",
    //   },
    // ],
  },
  {
    title: {
      cz: "Tisk z folie",
    },
    to: "tisk-z-folie",
  },
  {
    title: {
      cz: "Syntetická kůže",
    },
    to: "synteticka-kuze",
    // subNav: [
    //   {
    //     title: {
    //       cz: "Splinty",
    //     },
    //     to: "splinty",
    //   },
    //   {
    //     title: {
    //       cz: "Kleště",
    //     },
    //     to: "kleste",
    //   },
    //   {
    //     title: {
    //       cz: "Pojistné nitě",
    //     },
    //     to: "pojistne-nite",
    //   },
    // ],
  },
  {
    title: {
      cz: "Transfery",
    },
    to: "transfery",
  },
  {
    title: {
      cz: "Polygrafie",
    },
    to: "polygrafie",
  },
  {
    title: {
      cz: "Splintovací kleště",
    },
    to: "splintovaci-kleste",
  },
  {
    title: {
      cz: "Materiál",
    },
    to: "material",
  },
];

const SideNavigation = (props: Props) => {
  console.log();
  return (
    <div className="pl-1 lg:pl-4 mt-3 mb-6 text-xs xl:text-base">
      <ul>
        {navItems.map((navItem) => (
          <li key={navItem.title.cz} className="text-etka-blue font-bold">
            <Link activeClassName="underline" to={`/${navItem.to}`}>
              » {navItem.title.cz}
            </Link>
            {navItem.subNav ? (
              <ul>
                {navItem.subNav.map((subNavItem) => (
                  <li key={subNavItem.to} className="ml-3">
                    <Link
                      activeClassName="underline"
                      to={`/${navItem.to}/${subNavItem.to}`}
                    >
                      {subNavItem.title.cz}
                    </Link>
                  </li>
                ))}
              </ul>
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideNavigation;

import React from "react";
import HeaderNavigationLink, {
  HeaderNavigationLinkType,
} from "./HeaderNavigationLink";
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n";
import { Link } from "gatsby";

const HeaderNavigationEntries: HeaderNavigationLinkType[] = [
  {
    label: "Úvodní stránka",
    to: "/",
  },
  {
    label: "Symboly",
    to: "/symboly",
  },
  {
    label: "Vzorník barev",
    to: "/vzornik-barev",
  },
  {
    label: "Kontakty",
    to: "/kontakty",
  },
];

const HeaderNavigation = () => {
  // const langKey = getCurrentLangKey(["en", "cs"], "cs", location.pathname);
  return (
    <nav className="flex items-start sm:items-center justify-end py-3 pr-3 order-last sm:order-first">
      <div className="flex flex-wrap gap-1 sm:gap-3 items-center">
        {HeaderNavigationEntries.map((headerNavigationEntry) => (
          <HeaderNavigationLink
            key={headerNavigationEntry.label}
            link={headerNavigationEntry}
          />
        ))}
      </div>
    </nav>
  );
};

export default HeaderNavigation;

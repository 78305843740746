import React from "react";

interface Props {}

const Footer = (props: Props) => {
  return (
    <div className="w-full flex-grow">
      <footer className="text-xs sm:text-sm lg:text-base bg-gradient-to-b from-etka-blue to-etka-dark-blue flex justify-between text-white px-3 py-2 container mx-auto">
        <a
          href="https://www.google.com/maps/place/Lojovice+76,+251+69+Velk%C3%A9+Popovice"
          className="hover:underline"
          target="_blank"
        >
          <div>ETKA labels, s.r.o.</div>
          <div className="flex gap-1 sm:flex-row flex-col">
            <div>Lojovice 76</div>
            <div className="hidden sm:block">|</div>
            <div>251 69 Velké Popovice</div>
            <div className="hidden sm:block">|</div>
            <div>ČR</div>
          </div>
        </a>
        <div>
          <div className="flex gap-1 sm:flex-row flex-col">
            <div>
              Tel.:{" "}
              <a href="tel:+420323619060" className="hover:underline">
                +420 323 619 060
              </a>
            </div>
            <div className="hidden sm:block">|</div>
            <div>
              Email:{" "}
              <a href="mailto:office@etka.cz" className="hover:underline">
                office@etka.cz
              </a>
            </div>
          </div>
          <div className="text-right">
            <a className="hover:underline" href="http://www.etka.cz">
              www.etka.cz
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

import axios from "axios";
import csvtojson from "csvtojson";
import { useEffect, useState } from "react";

const urlOfTopBarSheet =
  "https://docs.google.com/spreadsheets/d/e/2PACX-1vS9x8ba9JWn3kLYCaJwDjVlrHqonPOjNdLmPKp91K_cRw0waVs5rEzUuc928FuIvs0yqOUYdkEeX5w3/pub?output=csv";

export default function useTopBar() {
  const [topBar, setTopBar] = useState([]);

  useEffect(() => {
    axios.get(urlOfTopBarSheet).then((response) => {
      setTopBar(response.data);
    });
  }, []);

  return topBar;
}

import React, { useEffect, useState } from "react";
import HeaderNavigation from "./HeaderNavigation";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { navItems } from "./SideNavigation";
import useTopBar from "../../hooks/useTopBar";

const Header = () => {
  const [expandedProducts, setExpandedProducts] = useState<boolean>(false);
  const topBar = useTopBar();

  function toggleProducts() {
    setExpandedProducts(!expandedProducts);
  }

  useEffect(() => {}, []);

  return (
    <div className="w-full">
      {topBar ? (
        <div className="bg-blue-300 px-2 flex justify-center">{topBar}</div>
      ) : null}
      <header className="h-26 w-full sm:h-72 overflow-hidden z-30 fixed sm:relative">
        <div className="sm:h-44 relative sm:bg-gradient-to-b sm:from-etka-dark-blue sm:to-etka-blue z-30 flex justify-between sm:block bg-gradient-to-r from-white via-etka-blue to-etka-blue">
          <HeaderNavigation />
          <div className="flex justify-between items-center pr-5">
            <div className="pl-3 sm:pl-10 py-5 sm:w-2/6 sm:bg-gradient-to-r from-white to-transparent">
              <Link to="/">
                <StaticImage
                  src="../../images/logo.png"
                  alt="Etka Labels Logo"
                  className="w-24 sm:w-32 lg:w-48"
                />
              </Link>
            </div>
            <div className="text-white hidden sm:block">
              <div className="font-bold text-md lg:text-3xl text-right">
                TEXTILNÍ ETIKETY
              </div>
              <div className="text-md lg:text-3xl text-right">
                S DLOUHOLETOU TRADICÍ
              </div>
              <div className="text-sm lg:text-xl text-right">od roku 1991</div>
            </div>
          </div>
        </div>
        <svg
          className="relative arch-shadow z-20 hidden sm:block -mt-px"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          viewBox="1.33 110 995 300"
        >
          <path
            fill="#0365a3"
            stroke="black"
            stroke-width="0"
            d="M 1.33,104.00
           C 1.33,104.00 0.67,172.00 0.67,173.33
             133.33,146.00 596.67,84.00 996.67,122.67
             996.67,122.67 997.33,102.67 997.33,102.67
             997.33,102.67 1.33,104.00 1.33,104.00 Z"
          />
        </svg>
        <div className="bg-blue-100 print:hidden sm:hidden pl-2 text-gray-500 ">
          <div className="cursor-pointer" onClick={toggleProducts}>
            {expandedProducts ? "- " : "+ "}Produkty
          </div>
          {expandedProducts ? (
            <ul>
              {navItems.map((navItem) => (
                <li key={navItem.title.cz} className="text-etka-blue font-bold">
                  <Link activeClassName="underline" to={`/${navItem.to}`}>
                    » {navItem.title.cz}
                  </Link>
                  {navItem.subNav ? (
                    <ul>
                      {navItem.subNav.map((subNavItem) => (
                        <li key={subNavItem.to} className="ml-3">
                          <Link
                            activeClassName="underline"
                            to={`/${navItem.to}/${subNavItem.to}`}
                          >
                            {subNavItem.title.cz}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </header>
    </div>
  );
};

export default Header;

import React from "react";
import SEO from "../seo";
import Footer from "./Footer";
import Header from "./Header";
import SideNavigation from "./SideNavigation";

const Layout = ({ noBottomPadding, children }) => {
  return (
    <div className="w-full h-full flex flex-col">
      <div className="container mx-auto bg-white">
        <Header />
        <div className="grid grid-cols-10 sm:-mt-8 z-0 px-4 md:px-8 lg:gap-20 sm:gap-10 gap-2 pt-28 sm:pt-0 container mx-auto bg-white">
          <div className="hidden sm:block sm:col-span-2 -mt-16 lg:-mt-12 xl:-mt-8">
            <div className="bg-gradient-to-b from-black to-gray-500 h-20 text-white flex justify-center items-end pb-1 md:pb-2 md:pt-20">
              <div className="w-11/12 text-xs md:text-sm lg:text-base border-2 border-dashed border-black text-center pb-1 pt-8 lg:pt-20 font-bold">
                Kategorie produktů
              </div>
            </div>
            <SideNavigation />
          </div>
          <div className={`col-span-10 sm:col-span-8 px-0 ${noBottomPadding ? '' : 'pb-10'}`}>{children}</div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
